import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Events" />

    <header className="header header--basic ">
        <div className="header__content">
            <div className="container">
                <div className="row">
                    <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                        <h3 className="header__category">Community</h3>
                        <h1 className="header__title">Events</h1>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div className="listing listing--events">
    
    <div className="listing__item ">
        <div className="container">
            <div className="row">
                <Link to="/events/salestribe-meet-melbourne/" className="listing__link">
                    <div className="listing__image col-md-push-6 col-md-6">
                        <img alt="Event" height="328" src="/media/images/Event-image1_pky9suY.2e16d0ba.fill-582x328.jpg" width="582"/>
                        <div className="datebox">
                            <time datetime="2019-09-12">20</time>
                            MAY
                        </div>
                    </div>
                    <div className="listing__content col-md-pull-6 col-md-6 col-lg-pull-5 col-lg-5">
                        <div className="listing__header">
                            <h2 className="listing__title">SalesTribe Meet-Up (Melbourne)</h2>
                            <h3 className="listing__location">May 20, 2021</h3>
                        </div>
                        <p className="h3">Steps to Success in Buyer Journey Mapping</p>
                        <div className="listing__buttons">
                            <button className="btn btn-link">Learn more</button>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    </div>
    
    </div>
    
    <FooterAlternate1 />

  </Layout>
)

export default IndexPage
